<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-form
      ref="item-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col cols="auto">
          <h2>จัดการวัตถุดิบ</h2>
        </v-col>
        <v-spacer />
        <v-col
          cols="auto"
          class="d-flex justify-center align-center">
          <v-btn
            class="px-8"
            color="success"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลวัตถุดิบ
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="4">
          <gw-drop-file
            v-model="formData.imageURL"
            need-compress
            :limit="1"
            s3-path="/purchases-items"
            caption="คลิกเพื่อเพิ่มรูปภาพ" />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <v-row>
            <v-col
              cols="12"
              md="4">
              <v-select
                v-model="formData.purchaseItemType"
                label="ประเภทวัตถุดิบ"
                color="secondary"
                :items="purchaseItemTypes"
                item-text="text"
                item-value="value"
                dense
                outlined
                hide-details
                :disabled="loading || isEdit" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <v-text-field
                v-model="formData.itemNo"
                label="รหัส"
                color="secondary"
                dense
                outlined
                hide-details
                disabled />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.description"
                :rules="[ ...textBoxRules, maxLengthRule(100) ]"
                label="ชื่อ"
                color="secondary"
                dense
                outlined
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.description2"
                label="รายละเอียด"
                color="secondary"
                :rules="[ ...textBoxRules, maxLengthRule(50) ]"
                dense
                outlined
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="formData.type"
                label="ประเภท"
                color="secondary"
                :items="types"
                dense
                outlined
                hide-details
                :disabled="loading" />
            </v-col>
            <!-- <v-col
              cols="12"
              md="3">
              <v-select
                v-model="formData.genProdPostingGroup"
                label="Gen Product Posting Group"
                color="secondary"
                :items="postingGroup"
                dense
                outlined
                hide-details
                :disabled="loading || isEdit" />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <v-select
                v-model="formData.invenPostingGroup"
                label="Inventory Posting Group"
                color="secondary"
                :items="postingGroup"
                dense
                outlined
                hide-details
                :disabled="loading || isEdit" />
            </v-col> -->
            <v-col
              cols="12"
              md="4">
              <v-select
                v-model="formData.baseUOM"
                label="หน่วยของสินค้า"
                color="secondary"
                :items="UOMs"
                :rules="[ ...textBoxRules ]"
                dense
                item-text="text"
                item-value="value"
                outlined
                hide-details
                :disabled="loading" />
            </v-col>
            <!-- <v-col
              cols="12"
              md="3">
              <v-select
                v-model="formData.salesUOM"
                label="หน่วยขายสินค้า"
                color="secondary"
                :items="UOMs"
                :rules="[ ...textBoxRules ]"
                dense
                item-text="text"
                item-value="value"
                outlined
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <v-select
                v-model="formData.purchaseUOM"
                label="หน่วยซื้อสินค้า"
                color="secondary"
                :items="UOMs"
                :rules="[ ...textBoxRules ]"
                dense
                outlined
                hide-details
                :disabled="loading" />
            </v-col> -->
            <v-col
              cols="12"
              md="4">
              <v-text-field
                v-model.number="formData.unitPrice"
                label="ราคาต่อหน่วย"
                color="secondary"
                type="number"
                dense
                outlined
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="4">
              <v-select
                v-model="formData.brand"
                label="แบรนด์"
                color="secondary"
                :rules="textBoxRules"
                :items="productAttributes.brands"
                item-text="name"
                item-value="name"
                dense
                outlined
                hide-details
                :disabled="loading || isEdit" />
            </v-col>
            <!-- <v-col
              cols="12"
              md="3">
              <v-text-field
                v-model.number="formData.unitCost"
                label="ต้นทุนต่อหน่วย"
                color="secondary"
                type="number"
                dense
                outlined
                hide-details
                :disabled="loading" />
            </v-col> -->
            <!-- <v-col
              cols="12"
              md="3"
              class="switch-container">
              <v-switch
                v-model="formData.lotControl"
                label="สินค้าคุม LOT"
                color="secondary"
                hide-details
                :disabled="loading" />
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import PurchaseItemsProvider from '@/resources/PurchaseItemsProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import UnitOfMeasuresProvider from '@/resources/UnitOfMeasuresProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'

const PurchaseItemsService = new PurchaseItemsProvider()
const ProductAttributeService = new ProductAttributeProvider()
const UnitOfMeasuresService = new UnitOfMeasuresProvider()

export default {
  data () {
    return {
      loading: false,
      isEdit: false,
      textBoxRules: [
        (v) => !!v || 'Field is require.'
      ],
      productAttributes: {
        brands: [],
        categories: [],
        colors: [],
        size: [],
        tags: [],
        collections: [],
        groupCategories: []
      },
      UOMs: [],
      formData: {
        itemNo: '',
        description: '',
        description2: '',
        type: 'Inventory',
        purchaseItemType: 'fabric',
        itemCategoryCode: '',
        baseUOM: 'PCS',
        salesUOM: 'PCS',
        purchaseUOM: 'PCS',
        unitPrice: 0,
        unitCost: 0,
        imageURL: '',
        blocked: false,
        lotControl: false,
        groupCategory: '',
        size: '',
        color: '',
        categories: '',
        gwCollection: '',
        invenPostingGroup: 'RM',
        genProdPostingGroup: 'RM',
        brand: '',
        code: ''
      },
      postingGroup: [
        'RM',
        'SEMI'
      ],
      types: [
        'Inventory',
        'Non-Inventory'
      ],
      purchaseItemTypes: [
        {
          text: 'ผ้า',
          value: 'fabric',
          type: 'RM'
        },
        {
          text: 'อะไหล่',
          value: 'material',
          type: 'RM'
        },
        {
          text: 'อุปกรณ์แพ็ค',
          value: 'packaging',
          type: 'RM'
        },
        {
          text: 'อุปกรณ์เสริม',
          value: 'supply',
          type: 'RM'
        },
        {
          text: 'ค่าแรงตัดเย็บ',
          value: 'labour',
          type: 'SEMI'
        },
        {
          text: 'ค่าขนส่ง',
          value: 'freight',
          type: 'SEMI'
        }
      ]
    }
  },
  watch: {
    'formData.purchaseItemType': {
      handler (val) {
        const purchaseItemType = this.purchaseItemTypes.find((p) => p.value === val)

        this.formData.invenPostingGroup = purchaseItemType.type
        this.formData.genProdPostingGroup = purchaseItemType.type
      },
      deep: true
    },
    'formData.baseUOM': {
      handler (val) {
        this.formData.salesUOM = val
        this.formData.purchaseUOM = val
      },
      deep: true
    }
  },
  async mounted () {
    await this.getAllAttributes()

    if (this.$route.params?.id) {
      this.isEdit = true
      this.getItem()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    maxLengthRule (length) {
      return (v) => v.length <= length || `Field นี้ต้องยาวไม่เกิน ${length} ตัวอักษร`
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: getImageOrGradientCss(color)
      }))
    },
    async getAllAttributes () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await ProductAttributeService.getAllProductAttribute()

        this.productAttributes = {
          colors: Array.isArray(data.colors) ? this.getColor(data.colors) : [],
          brands: Array.isArray(data.brands) ? data.brands : [],
          size: Array.isArray(data.size) ? data.size : [],
          tags: Array.isArray(data.tags) ? data.tags : [],
          categories: Array.isArray(data.categories) ? data.categories : [],
          collections: Array.isArray(data.collections) ? [{ id: null, name: 'ไม่ระบุ' }, ...data.collections] : [],
          groupCategories: Array.isArray(data.groupCategories) ? data.groupCategories : []
        }

        const { data: uom } = await UnitOfMeasuresService.getItems({
          page: 1,
          limit: 9999
        })

        this.UOMs = uom.results.map((r) => ({
          text: r.name,
          value: r.code
        }))
      } catch (error) {
        console.error('getAllAttributes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async getItem () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await PurchaseItemsService.getItemById(this.$route.params.id)

        this.formData = { ...data }
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onSubmit () {
      const validated = await this.$refs['item-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        if (this.isEdit) {
          await PurchaseItemsService.updateItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'แก้ไข Purchase Item สำเร็จ',
            type: 'success'
          })
        } else {
          await PurchaseItemsService.createItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'สร้าง Purchase Item สำเร็จ',
            type: 'success'
          })
        }

        this.$router.push({ name: 'PurchasesItems' })
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
